<template>
  <div class="progress-container">
    <div class="segment" :style="{ background: segmentColor() }"/>
  </div>
</template>


<script>
export default {
  Name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      blinking: 0,
    };
  },
  methods: {
    calculate_transparency(section){
      // const transp = Math.min(Math.max(Math.exp(-4 + 4*((this.progress/section))),0), 1)
      const transp = this.progress < section ? 0.25 : 1;
      return transp
    },
    segmentColor() {

      if (this.progress === 0 )
        return `linear-gradient(135deg, rgba(254, 204, 0, 1) 0%, rgba(254, 125, 3, 0.1}) 25%, rgba(244, 29, 18, 0.1) 50%, rgba(129, 11, 155, 0.1) 75%, rgba(51, 0, 247, 0.1) 100%)`
      else
        return `linear-gradient(135deg, rgba(254, 204, 0, ${this.calculate_transparency(20)}) 0%, rgba(254, 125, 3, ${this.calculate_transparency(40)}) 25%, rgba(244, 29, 18, ${this.calculate_transparency(60)}) 50%, rgba(129, 11, 155, ${this.calculate_transparency(80)}) 75%, rgba(51, 0, 247, ${this.calculate_transparency(100)}) 100%)`
    },
  },
};
</script>

<style>
.progress-container {
  display: flex;
  animation: blink 2s infinite;
  border-radius: 10px;
}

.segment {
  width: 100%;
  height: 45px;
  background: rgb(217, 217, 217);


}
@keyframes blink{
  0% {
    background: rgba(244, 29, 18, 0.0)
  }
  10% {
    background: rgba(244, 29, 18, 0.05)
  }
  20% {
    background: rgba(244, 29, 18, 0.1)
  }
  30% {
    background: rgba(244, 29, 18, 0.15)
  }
  40% {
    background: rgba(244, 29, 18, 0.2)
  }
  50% {
    background: rgba(244, 29, 18, 0.25)
  }
  60% {
    background: rgba(244, 29, 18, 0.20)
  }
  70% {
    background: rgba(244, 29, 18, 0.15)
  }
  80% {
    background: rgba(244, 29, 18, 0.10)
  }
  90% {
    background: rgba(244, 29, 18, 0.05)
  }
  100% {
    background: rgba(244, 29, 18, 0.0)
  }

}

/* .segment:not(:first-child) {
  margin-left: -10px;
  margin-right: -10px;
} */

.segment:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.segment:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
