<template>
  <v-card fill-height fluid>

    <!-- <v-card-actions> -->
    <div class="pl-3 pt-3">
      <v-btn color="warning" variant="tonal" @click="back">Back</v-btn>
      <v-spacer />
    </div>
    <!-- <v-btn color="success" variant="tonal" @click="replayVideo">Replay</v-btn> -->
    <!-- </v-card-actions> -->
    <!-- <v-card-text> -->
    <Replay :id="id" :replayExercise="replayExercise" />
    <!-- </v-card-text> -->

  </v-card>
</template>

<script>
import Replay from '@/components/templates/Replay'
import router from '@/routes/index'


export default {
  name: 'SaaSReplay',
  props: ['id'],
  components: {
    Replay
  },
  computed: {

  },
  data: () => ({
    replayExercise: 0,
    ejercicio: '',
    replay: false,
    errors: [],
    exerciseData: {},
    reps: 0,
    bien: 0,
    mal: 0,
    currentFrame: 0,
    hideVideo: false,
    videoUrl: '',
    itemsPerPage: 5,
    results: [],
    headers: [
      { title: 'Timestamp', align: 'end', key: 'ts' },
      { title: 'Bien', align: 'end', key: 'bien' },
      { title: 'Total', align: 'end', key: 'total' }
    ],
    resultIds: [],
    userId: '1234',
    videoFile: null,
    videoData: null,
    unsubscribe: null,
    reader: null,
    url: null,
    rules: [
      value => {
        return !value || !value.length || value[0].size < 100000000 || 'File size should be less than 100 MB!'
      },
    ],
  }),
  mounted() {

  },
  unmounted() {
  },
  methods: {
    replayVideo() {
      this.replayExercise += 1;
    },
    back() {
      router.go(-1);
    }

  },
}
</script>

<style>
.text-field-transparent .v-input__control {
  background: rgb(255, 205, 205) !important;
}
</style>
