

<template>
  <div class="mx-auto">
    <v-layout>
      <v-app-bar>
        <template v-slot:prepend>
          <v-app-bar-nav-icon icon="mdi-home" color="white" @click="goHome()">

        </v-app-bar-nav-icon>
        <!-- <v-btn color="error">
            <v-icon icon="md:home"></v-icon>
          </v-btn> -->
        </template>

        <v-app-bar-title style="font-weight: bold">Revelify</v-app-bar-title>

        <template v-slot:append>
          <!-- <v-btn icon="mdi-dots-vertical"></v-btn> -->
          <div role="alert" v-if="user.loggedIn">
            <div class="my-4" color="white">
              <v-btn @click.prevent="signOut" color="white"><b>Log Out</b></v-btn>
            </div>
          </div>
        </template>
      </v-app-bar>

      <v-main>
        <!-- <v-container fluid> -->
        <router-view fluid class="elevation-0"></router-view>
        <!-- </v-container> -->
      </v-main>
    </v-layout>
  </div>
</template>

<script>
// import navbar from "./components/molecules/Navbar";
// import HelloWorld from './views/HelloWorld.vue'
import router from '@/routes/index'
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import { computed } from "vue";

export default {
  name: 'App',

  components: {
    // navbar
    // HelloWorld,
  },

  data: () => ({
    //
  }),
  methods: {
    goHome() {
      router.push('/')
    }
  },
  setup() {

    const store = useStore()
    // const router = useRouter()

    

    const user = computed(() => {
      return store.getters.user;
    });

    const signOut = async () => {
      store.dispatch('logOut')
      router.push('/')
    }

    return { user, signOut }
  },
}
</script>
<style scoped>

.app {
  /* height: 100vh; */
}
.v-toolbar{

  background: linear-gradient(135deg, rgba(254, 204, 0, 1) 0%, rgba(254, 125, 3, 1) 25%, rgba(244, 29, 18, 1) 50%, rgba(129, 11, 155, 1) 75%, rgba(51, 0, 247, 1) 100%) !important;
}
.v-app-bar-title{
  color: white;
}

</style>
