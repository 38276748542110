<template>
  <v-container class="main pt-0">
    <v-img :src="require('../assets/img/background_saas.png')" class="pa-0" contain height="500px" />
    <div class="buttons">
      <div class="button">
        <router-link to="/saas-upload">
          <v-img class="mb-2" :src="require('../assets/img/upload.png')" width="100px" />
        </router-link>
        <h3>Upload</h3>
      </div>
      <div class="button">
        <router-link to="/saas-stored">
          <v-img class="mb-2" :src="require('../assets/img/play.png')" height="72px" />
        </router-link>
        <h3>Check records</h3>
      </div>
    </div>
    <!-- </v-img> -->
    <!-- <div class="bnd_image">

    </div> -->

  </v-container>
</template>

<script>
export default {
  name: 'SaasHome'
}
</script>
<style scoped>
/* local styles */
.main {
  background-size: cover;
  height: 90vh !important;
  background: linear-gradient(135deg, rgba(254, 204, 0, 1) 0%, rgba(254, 125, 3, 1) 25%, rgba(244, 29, 18, 1) 50%, rgba(129, 11, 155, 1) 75%, rgba(51, 0, 247, 1) 100%);
}


.buttons {
  position: absolute;
  top: 100px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
  width: 100%;
  margin: auto;

}

.button {
  margin: auto;
  text-align: center;
  color: white;
}
</style>
