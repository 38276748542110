<template>
  <v-container>

    <v-row class="text-center">
      <v-col class="mb-1" cols="8">
        <h1 class="display-2 font-weight-bold mb-3">
          Real Time
        </h1>

      </v-col>
      <v-col cols="4">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="100"
        />
      </v-col>


    </v-row>
    <v-row>
      <PoseModel :duracion="duracion" :ejercicio="ejercicio"/>
    </v-row>
  </v-container>
</template>

<script>
import PoseModel from "@/components/templates/PoseModel"

export default {
  name: 'CameraPose',
  props: ['duracion', 'ejercicio'],
  components: {
    PoseModel
  },
  data: () => ({

  }),
  mounted() {

  },
}
</script>
