<template>
  <v-container class="main">
    <div class="mt-10">
    <v-img
          :src="require('../assets/logo.png')"
          class="mb-5"
          contain
          height="100"
        />
    <h1>Revelify Demo Site</h1>
    <h2 class="mb-15">Under Maintenance</h2>

    <br>
    <div v-if="isSignedIn">
      <button @click="handleSignOut">Sign Out</button>
    </div>
  </div>
  </v-container>
</template>

<script>
// import { useRouter } from "vue-router";


export default {
  name: "DashboardComponent",






};
</script>

<style scoped>
.main {
  background: url('../assets/img/main_bnd.jpg');
  background-size: cover;
  height: 90vh;
  text-align: center;
  color: white;
}

.welcome {
  color: white;
}

.buttons {
  /* position: absolute; */
  bottom: 150px;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  margin:auto;

}
.button {
  margin: auto;
  height: 60%;
}
</style>
