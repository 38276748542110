<template>
  <div class="mt-10">
    <v-img
          :src="require('../assets/logo.png')"
          class="mb-5"
          contain
          height="100"
        />
    <h1>Revelify Demo Site</h1>
    <h2 class="mb-15">Under Maingenance</h2>

    <br>
    <div v-if="isSignedIn">
      <button @click="handleSignOut">Sign Out</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import {auth, ui} from '@/plugins/firebase';
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';

// import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import { signOut } from "firebase/auth";
import router from '@/routes/index'
import { useStore } from "vuex";


export default {
  name: 'LoginView',
  props: {
    msg: String
  },
  data: () => ({
    user: '',
    isSignedIn: false,
  }),
  mounted() {
    const user = ref(null);
    const store = useStore()
    auth.onAuthStateChanged(user => {
      store.dispatch("fetchUser", user);
      if (user) {
        router.push('/')
      }
    });
    const isSignedIn = ref(false);

    const uiConfig = {
      signInFlow: 'popup',
      signinSuccessUrl: 'http://localhost:8080/',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: function (authResult) {
          user.value = authResult.user.displayName;
          isSignedIn.value = true;
          router.push('/')

          // so it doesn't refresh the page
          return false;
        },
        uiShown: function() {
          // The widget is rendered.
          // Hide the loader.
          document.getElementById('loader').style.display = 'none';
        }
      }
    }
    // Initialize the FirebaseUI Widget using Firebase.
    // var ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('#firebaseui-auth-container', uiConfig);
    const handleSignOut = () => {
      signOut(auth).then(() => {
       // Sign-out successful.
       user.value = null;
       isSignedIn.value = false;
       console.log('Signed out');
       ui.start('#firebaseui-auth-container', uiConfig);
      }).catch((error) => {
        // An error happened.
        console.log(error);
      });
    }
    return {
      user,
      isSignedIn,
      handleSignOut,
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    text-align:center;
}
h2 {
    text-align:center;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
