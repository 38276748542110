<template>
  <Pie :data="dataset" :options="options" />
</template>


<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
// import {Chart, ArcElement} from 'chart.js'

import { Pie } from 'vue-chartjs'

ChartJS.register(ArcElement, Tooltip, Legend);


export default {
  name: 'PieChart',
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          labels: ['Bien', 'Mal', ''],
          datasets: [
            {
              backgroundColor: ['#41B883', '#E46651', '#FFFFFF'],
              data: [0, 0, 100]
            }
          ]
        }
      }
    }
  },
  components: {
    Pie
  },
  data() {
    return {
      options: {
        borderColor: "#000000",
        responsive: true,
        maintainAspectRatio: false
      },
    }
  }
}

</script>
