<template>
  <v-container class="main">
    <div align="center" justify="center" v-if="user.loggedIn" class='mb-5'>
      <v-img :src="require('../assets/img/logo_horizontal.png')" class="mb-5" contain height="100" />
      <h2 class="welcome">Welcome {{ user.data.displayName }}</h2>
    </div>
    <div class="buttons">
      <div class="button">
        <router-link to="/realtime">
          <v-img :src="require('../assets/img/realtime_button.png')" class="mb-5"  width="100px" />
        </router-link>
      </div>
      <div class="button">
        <router-link to="/saas-home">
          <v-img :src="require('../assets/img/offline_button.png')" class="mb-5"  width="100px" />
        </router-link>
      </div>
    </div>
  </v-container>
</template>

<script>
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import router from '@/routes/index'
import { computed } from "vue";
import { auth } from '@/plugins/firebase'


export default {
  name: "DashboardComponent",

  setup() {

    const store = useStore()
    // const router = useRouter()

    auth.onAuthStateChanged(user => {
      store.dispatch("fetchUser", user);
      if (!user) {
        router.push('/login-demo')
      }
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const signOut = async () => {
      await store.dispatch('logOut')
      router.push('/login-demo')
    }

    return { user, signOut }
  },




};
</script>

<style scoped>
.main {
  background: url('../assets/img/main_bnd.jpg');
  background-size: cover;
  height: 90vh;
}

.welcome {
  color: white;
}

.buttons {
  /* position: absolute; */
  bottom: 150px;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  margin:auto;

}
.button {
  margin: auto;
  height: 60%;
}
</style>
