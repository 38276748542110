<template>
  <div class="container">
    <v-row>
      <v-col cols="3">
        <router-link to="/setup">
          <v-btn variant="flat" color="indigo-lighten-4">
            Back
          </v-btn>
        </router-link>
      </v-col>
      <v-col>
        <h1>{{ `${ejercicio}: ${countdown}` }}</h1>
      </v-col>
      <v-col cols="3" class="text-right">
        <router-link :to="`/finish/${ejercicio}/${good}/${bad}`">
          <v-btn variant="flat" color="indigo-lighten-4">
            Finish
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
      <video ref="input_video" width="100%" height="100%"></video>
      <canvas
        class="output_canvas"
        ref="output_canvas"
        :width="width"
        :height="height"
      ></canvas>
    </v-col>
    <v-col cols="3">
      <v-list lines="two" max-height="320" class="overflow-y-auto">
          <v-header><h2>ERRORES</h2></v-header>
        <v-list-item
          class="text-wrap"
          v-for="item in items"
          :key="item.rep_n"
          :title="`Repetition: ${item.rep_n}`"
          :subtitle="item.msg"
        ></v-list-item>
      </v-list>
    </v-col>
    </v-row>
  </div>
</template>
<script>

import { Pose, POSE_CONNECTIONS } from "@mediapipe/pose/pose";
import { Camera } from "@mediapipe/camera_utils";
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
// import socket from "@/plugins/socket";
import { io } from "socket.io-client";
// const URL = "http://localhost:5000";
let socket = null;
const URL = "https://revelify-geah2nlgea-no.a.run.app";


export default {
  name: "PoseModel",
  props: ['duracion', 'ejercicio'],
  data: function() {
    return {
      invervalId: 0,
      countdown: 0,
      firstTime: true,
      voice: null,
      firstSpeech: false,
      text: '',
      isLoading: true,
      selectedVoice: 4,
      synth: window.speechSynthesis,
      voiceList: [],
      greetingSpeech: new window.SpeechSynthesisUtterance(),
      lastPlayed: {rep_n: 0, msg: ''},
      items: [],
      good: 0,
      bad: 0,
      total: 0,
      errors: [],
      n_rep: [],
      number: null,
      ctx: null,
      width: null,
      height: null,
      segmentation: false,
      userId: '1234',
      camera: null,
    };
  },
  computed: {
    inputVideo() {
      return this.$refs.input_video;
    },
  },
  mounted() {
    this.voiceList = this.synth.getVoices();

    this.synth.onvoiceschanged = () => {
      this.voiceList = this.synth.getVoices()
      this.voice = this.voiceList.filter(voice => {
        return voice.lang == 'es-ES'
      })[0]
      // console.log(this.voiceList)

    }
    this.listenForSpeechEvents()


    this.ctx = this.$refs.output_canvas.getContext("2d");

    this.init();
  },
  methods: {
    listenForSpeechEvents () {
      this.greetingSpeech.onstart = () => {
        this.isLoading = true
      }

      this.greetingSpeech.onend = () => {
        this.isLoading = false
      }
    },

    /**
     * Shout at the user
     */
    greet (text) {
      // it should be 'craic', but it doesn't sound right
      this.greetingSpeech.text = `${text}`
      // if (this.firstSpeech) {
      this.greetingSpeech.voice = this.voice//this.voiceList[this.selectedVoice]
      //   this.firstSpeech = true
      // }


      this.synth.speak(this.greetingSpeech)
    },
    websocketConnect(userId) {
      socket = io(URL, {
        autoConnect: false,
        extraHeaders: {
          'ejercicio': this.ejercicio
        }
      });
      socket.on("connect_error", (err) => {
        if (err.message === "invalid username") {
          this.usernameAlreadySelected = false;
        }
      });
      socket.auth = { userId };

      socket.connect();
      socket.on('message', (data) => {
        // console.log('Results for ', socket.id, ': ', data);
        this.total = data.total || this.total
        this.good = data.bien || this.good
        this.bad = this.total - this.good
        this.errors = data.errors || this.errors
        this.n_rep = data.n_rep || this.n_rep
        this.items = []
        for (let i=0; i< this.n_rep.length; i++){
          this.items.push({rep_n: this.n_rep[i], msg: this.errors[i]})
          if (i == this.n_rep.length - 1) {
            if (this.lastPlayed.rep_n !== this.n_rep[i] && this.lastPlayed.msg !== this.errors[i]){
              this.text = this.errors[i]
              this.lastPlayed.rep_n = this.n_rep[i]
              this.lastPlayed.msg = this.errors[i]
              this.greet(this.text)
            }

          }
        }
        // check last error and play it


      });
    },
    init() {
      this.websocketConnect(this.userId);

      const pose = new Pose({locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
      }});

      pose.setOptions({
        modelComplexity: 2,
        smoothLandmarks: true,
        enableSegmentation: this.segmentation,
        smoothSegmentation: this.segmentation,
        minDetectionConfidence: 0.9,
        minTrackingConfidence: 0.9
      });
      pose.onResults(this.onResults);
      this.camera = new Camera(this.inputVideo, {
        onFrame: async () => {
          await pose.send({ image: this.inputVideo });
        },
        width: 1280,
        height: 720
      });




      this.camera.start();

    },
    onResults(results) {
      if (this.firstTime){
        this.firstTime = false;
        this.countdown = this.duracion;
        // setTimeout(() => {
        //   this.router.push(`/finish/:${this.ejercicio}/:${this.good}/:${this.bad}`)
        //   clearInterval(this.invervalId)
        // }, this.duracion);
        this.invervalId = setInterval(() => {
          this.countdown -= 1;
          if (this.countdown == 0){
            this.$router.push(`/finish/${this.ejercicio}/${this.good}/${this.bad}`)
            clearInterval(this.invervalId)
          }
        }, 1000);
      }
      if (!results.poseLandmarks) {
        this.width = results.image.width;
        this.height = results.image.height;
        this.ctx.save();
        this.ctx.clearRect(0, 0, results.image.width, results.image.height);

        // Only overwrite existing pixels.
        this.ctx.globalCompositeOperation = 'source-in';
        this.ctx.fillStyle = '#00FF00';
        this.ctx.fillRect(0, 0, results.image.width, results.image.height);

        // Only overwrite missing pixels.
        this.ctx.globalCompositeOperation = 'destination-atop';
        this.ctx.drawImage(
            results.image, 0, 0, results.image.width, results.image.height);

        this.ctx.restore();
        this.ctx.font = "60px Arial";
        this.ctx.fillStyle = "green";
        this.ctx.fillText('Good: '+this.good, 30, 70);
        this.ctx.fillStyle = "red";
        this.ctx.fillText('Bad: '+this.bad, 30, 150);
        this.ctx.fillStyle = "blue";
        this.ctx.fillText('Total: '+this.total, 1000, 70);
        return;
      }

      this.width = results.image.width;
      this.height = results.image.height;
      this.ctx.save();
      this.ctx.clearRect(0, 0, results.image.width, results.image.height);
      if (this.segmentation)
      this.ctx.drawImage(results.segmentationMask, 0, 0,
                          results.image.width, results.image.height);

      // Only overwrite existing pixels.
      this.ctx.globalCompositeOperation = 'source-in';
      this.ctx.fillStyle = '#00FF00';
      this.ctx.fillRect(0, 0, results.image.width, results.image.height);

      // Only overwrite missing pixels.
      this.ctx.globalCompositeOperation = 'destination-atop';
      this.ctx.drawImage(
          results.image, 0, 0, results.image.width, results.image.height);
      // console.log(results)
      // console.log(POSE_CONNECTIONS)
      this.ctx.globalCompositeOperation = 'source-over';
      drawConnectors(this.ctx, results.poseLandmarks, POSE_CONNECTIONS,
                    {color: '#00FF00', lineWidth: 4});
      drawLandmarks(this.ctx, results.poseLandmarks,
                    {color: '#FF0000', lineWidth: 2});

      this.ctx.restore();
      this.ctx.font = "60px Arial";
      this.ctx.fillStyle = "green";
      this.ctx.fillText('Good: '+this.good, 30, 70);
      this.ctx.fillStyle = "red";
      this.ctx.fillText('Bad: '+this.bad, 30, 150);
      this.ctx.fillStyle = "blue";
      this.ctx.fillText('Total: '+this.total, 1000, 70);


      // Send pose to backend
      // console.log(this.userId, results.poseLandmarks[0])
      // socket.emit(this.userId, {ex: 'ex1', pose: results.poseLandmarks[0]});
      socket.emit('message', {ex: 'ex1', landmark: results.poseLandmarks,
                                          height: results.image.height,
                                          width: results.image.width});


      // console.log(results.poseLandmarks)

      // grid.updateLandmarks(results.poseWorldLandmarks);
    },
  },
  created() {

  },

  unmounted() {
    socket.off("connect_error");
    this.camera.stop();
    clearInterval(this.invervalId)

  },
};
</script>

<style scoped>
.output_canvas {
  width: 100%;
  height: 100%;
}
</style>
