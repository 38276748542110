<template>
  <v-card fill-height fluid>
    <v-row class="pl-2 pr-2">
      <v-col cols="6">
        <h2 class="display-2 font-weight-bold mb-3">
          Upload a video
        </h2>
      </v-col>
    </v-row>
    <v-row class="pl-2 pr-2">
      <v-col cols="4">
        <v-file-input v-model="videoFile" clearable show-size :rules="rules" label="File input" prepend-icon="mdi-video"
          accept="video/mp4" @click:clear="videoFile = null" @change="previewVideo"></v-file-input>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="ejercicio"
          label="Ejercicio"
          :items="['Airsquat', 'Burpee']"
        ></v-select>
      </v-col>
      <v-col cols="6" class="mt-5">
        <v-btn color="success" @click="upload" v-show="videoFile != null">Upload Video</v-btn>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" class="pl-2 pr-2">

      <v-col col="6">
        <v-table>
          <thead>
            <tr>
              <th class="text-left">
                Nombre
              </th>
              <th class="text-left">
                Ejercicio
              </th>
              <th class="text-left">
                Bien
              </th>
              <th class="text-left">
                Total
              </th>
              <th class="text-left">
              </th>
              <th class="text-left">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in results" :key="item.id">

              <td>{{ item.filename || item.ts }}</td>
              <td>{{ item.ejercicio || 'airsquat' }}</td>
              <td>{{ item.bien }}</td>
              <td>{{ item.total }}</td>
              <td v-if="item.progress == 1.0">
                <v-btn icon="mdi-play" color="primary" @click="getVideo(item.id)" size="xs-small">
                </v-btn>

              </td>
              <td v-else>
                <v-progress-circular :model-value="item.progress * 100" color="blue-grey"></v-progress-circular>
              </td>
              <td><v-btn icon="mdi-delete" color="warning" @click="deleteVideo(item.id)" size="xs-small"></v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>

      </v-col>
      <v-col cols="6" >
        <v-text-field class="text-field-transparent" readonly v-for="error in errors" :key="error">
          {{ error }}
        </v-text-field>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" class="pl-2 pr-2">
      <v-col cols="6" class="d-flex justify-center align-center">
        <video autoplay class="video" id="video-preview" controls v-show="!hideVideo && (videoFile != null || videoUrl != '')" />
      </v-col>
      <v-row v-show="replay">
        <v-col cols="4">
          <div>
            <h1>Total: </h1>
            <h2>{{ reps }}</h2>
          </div>
          <div class="bien">
            <h1>Bien: </h1>
            <h2>{{ bien }}</h2>
          </div>
          <div class="mal">
            <h1>Mal: </h1>
            <h2>{{ mal }}</h2>
          </div>
        </v-col>
        <v-col cols="8">
          <!-- {{ reps}} {{ errors }} -->
          <Pie :dataset="dataset" />
        </v-col>
      </v-row>

    </v-row>



  </v-card>
</template>

<script>
import { auth, db, storage } from '@/plugins/firebase'
import { collection, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import Pie from '@/components/molecules/Pie'

import { ref, getDownloadURL, deleteObject } from "firebase/storage";
import router from '@/routes/index'

export default {
  name: 'SaaS',
  components: {
    Pie
  },
  computed: {
    dataset() {
      return {
        labels: ['Bien', 'Mal'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651'],
            data: [this.bien, this.mal]
          }
        ]
      }
    },
  },
  data: () => ({
    ejercicio: '',
    replay: false,
    errors: [],
    selectedVideo: {},
    reps: 0,
    bien: 0,
    mal: 0,
    currentFrame: 0,
    hideVideo: false,
    videoUrl: '',
    itemsPerPage: 5,
    results: [],
    headers: [
      { title: 'Timestamp', align: 'end', key: 'ts' },
      { title: 'Bien', align: 'end', key: 'bien' },
      { title: 'Total', align: 'end', key: 'total' }
    ],
    resultIds: [],
    userId: '1234',
    videoFile: null,
    videoData: null,
    unsubscribe: null,
    reader: null,
    url: null,
    rules: [
      value => {
        return !value || !value.length || value[0].size < 100000000 || 'File size should be less than 100 MB!'
      },
    ],
  }),
  mounted() {
    // Add firestore listener


    // Get user id
    auth.onAuthStateChanged(user => {

      this.userId = user.uid
      this.sub_db();
      if (!user) {
        router.push('/login')
        console.log('not logged in ', this.user.loggedIn)
      }
    });
  },
  unmounted() {
    this.unsubscribe();
  },
  methods: {
    async deleteVideo(id) {
      this.errors = []
      this.replay = false;
      // delete from the list
      const index = this.resultIds.indexOf(id);
      this.resultIds.splice(index, 1);
      const toDelete = this.results.filter(obj => id == obj.id);

      const fileName = toDelete[0].url;
      this.results = this.results.filter(obj => id != obj.id);


      // Get a reference to the file
      const fileRef = ref(storage, fileName);

      // Delete entry in firestore
      await deleteDoc(doc(db, this.userId, id));
      // Create a reference to the file to delete


      // Delete the file
      deleteObject(fileRef).then(() => {
        // File deleted successfully
      }).catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error)
      });

    },
    getVideo(id) {
      this.errors = []
      this.hideVideo = false;
      this.replay = true;
      this.selectedVideo = this.results.filter(obj => id == obj.id)[0];
      if (this.selectedVideo.ts != 'Processing') {
        const fileName = this.selectedVideo.url;

        this.dataset = {
          labels: ['Bien', 'Mal'],
          datasets: [
            {
              backgroundColor: ['#41B883', '#E46651'],
              data: [this.bien, this.mal]
            }
          ]
        }

        // Get a reference to the file
        const fileRef = ref(storage, fileName);

        // Get the download URL for the file
        getDownloadURL(fileRef)
          .then((url) => {
            // Create a new HTML video element and set its source to the download URL
            const videoElement = document.getElementById('video-preview');
            videoElement.src = url;
            videoElement.addEventListener("timeupdate", () => {

              this.currentFrame = Math.round(videoElement.currentTime * this.selectedVideo.fps)
              // console.log(videoElement.currentTime, this.selectedVideo.fps, this.currentFrame, this.reps)
              this.reps = this.selectedVideo.rep_total.filter(number => number < this.currentFrame).length
              this.bien = this.selectedVideo.rep_bien.filter(number => number < this.currentFrame).length
              this.mal = this.selectedVideo.rep_mal.filter(number => number < this.currentFrame).length
              this.errors = this.selectedVideo[`${this.reps}`]
              // console.log("The currentTime attribute has been updated. Again.");
            });

            this.videoUrl = url;
          })
          .catch((error) => {
            console.error(error);
          });
      }

    },
    async sub_db() {
      // Read documents from a collection
      // const querySnapshot = await getDocs(collection(db, this.userId));
      // querySnapshot.forEach((doc) => {
      //   console.log(`${doc.id} => ${doc.data()}`);
      //   this.results.append({id: doc.id, data: doc.data()})
      // });
      this.unsubscribe = onSnapshot(collection(db, this.userId), (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          if (!this.resultIds.includes(doc.id)) {
            this.resultIds.push(doc.id);

            if (data.url == "") {
              data.ts = 'Processing'
            } else {
              data.ts = data.ts ? data.ts.toDate().toLocaleDateString() : data.ts;
            }
            this.results.push(data);
          } else {
            data.ts = data.ts ? data.ts.toDate().toLocaleDateString() : data.ts;
            this.results = this.results.filter(obj => doc.id != obj.id);
            this.results.push(data);
          }

        });
      });
    },
    async upload() {
      this.errors = []
      console.log('upload')
      const formData = new FormData();
      formData.append('video', this.videoFile[0]);
      formData.append('uid', this.userId);
      formData.append('orgfilename', this.videoFile[0].name)
      formData.append('ejercicio', this.ejercicio.toLowerCase());
      this.hideVideo = true;
      this.videoFile = null;

      // const response = await fetch('http://localhost:8000/upload', {
      const response = await fetch('https://revelify-saas-geah2nlgea-no.a.run.app/upload', {
        method: 'POST',
        mode: 'no-cors',
        body: formData
      });
      // console.log(response)
      if (response.ok) {
        console.log('Video uploaded successfully');

      } else {
        console.error('Error uploading video');
      }
    },
    previewVideo() {
      this.errors = []
      this.hideVideo = false;
      this.replay = false;
      this.selectedVideo = {};
      // console.log(this.videoFile)
      // this.url= URL.createObjectURL(this.videoFile[0])
      let video = document.getElementById('video-preview');
      this.reader = new FileReader();
      this.reader.readAsDataURL(this.videoFile[0]);
      this.reader.addEventListener('load', function (event) {
        video.src = this.reader.result;
        this.videoData = {
          name: this.videoFile[0].name,
          type: this.videoFile[0].type,
          data: event.target.result,
        };

      }.bind(this));
    }
  },
}
</script>

<style>
.text-field-transparent  .v-input__control {
  background: rgb(255, 205, 205) !important;
}

.bien {
  color: green;
}

.mal {
  color: red;
}

video {
  height: 50vh;
  /* width: 100vw; */
  /* object-fit: fill; */
}
</style>
