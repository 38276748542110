<template>
  <div class="counter-box">
    <div class="kpi-card orange">
      <span class="card-value">Tiempo: <a>{{ value }}</a></span>
      <!-- <span class="card-value">  </span> -->
    </div>

  </div>
</template>


<script>
export default {
  Name: 'CronoDisplay',
  props: {
    value: {
      type: String,
      default: "00:00",
    },
  },
  data() {
    return {
      blinking: 0,
    };
  },
  methods: {
  },
};
</script>

<style>
.counter-box {
  display: flex;
  border-radius: 10px;
}

.orange {
  background: linear-gradient(135deg, rgba(254, 204, 0, 1) 0%, rgba(254, 125, 3, 1) 25%, rgba(244, 29, 18, 1) 50%, rgba(129, 11, 155, 1) 75%, rgba(51, 0, 247, 1) 100%);
  color: #fff;
  text-align: center;
}

.kpi-card {
  margin: auto;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
  ;
  display: inline-block;
  float: left;
  /* padding: 1em; */
  border-radius: 0.3em;
  font-family: sans-serif;
  width: 200px;
  min-width: 120px;
  /* margin-left: 0.5em;
  margin-top: 0.5em; */
}

.card-value {
  display: block;
  font-size: 150%;
  font-weight: bolder;
}

.card-text {
  display: block;
  font-size: 70%;
  padding-left: 0.2em;
}
</style>
