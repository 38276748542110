import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue';
import LoginDemo from '../views/LoginDemo.vue';
import Register from '../views/Register.vue';
import Home from '../views/Home.vue';
import HomeDemo from '../views/HomeDemo.vue';
import CameraPose from '@/views/CameraPose';
import RealTime from '@/views/RealTime';
import FinishScreen from '@/views/FinishScreen';
import SaaS from '@/views/SaaS';
import SaasHome from '@/views/SaasHome';
import SaasReplay from '@/views/SaasReplay';
import SaasStored from '@/views/SaasStored';
import SaasUpload from '@/views/SaasUpload';
import SaasResults from '@/views/SaasResults';


const routes = [
  {
    path: '/login-demo',
    name: 'login',
    component: Login
  },
  {
    path: '/login',
    name: 'login',
    component: LoginDemo
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home-demo',
    name: 'HomeDemo',
    component: HomeDemo
  },
  {
    path: '/camera/:ejercicio/:duracion',
    name: 'Camera',
    component: CameraPose,
    props: true
  },
  {
    path: '/finish/:ejercicio/:bien/:mal',
    name: 'Results',
    component: FinishScreen,
    props: true
  },
  {
    path: '/realtime',
    name: 'RealTime',
    component: RealTime
  },
  {
    path: '/saas-home',
    name: 'SaaSHome',
    component: SaasHome
  },
  {
    path: '/saas-upload',
    name: 'SaaSUpload',
    component: SaasUpload
  },
  {
    path: '/saas-stored',
    name: 'SaaSStored',
    component: SaasStored
  },
  {
    props: true,
    path: '/saas-replay/:id',
    name: 'SaaSReplay',
    component: SaasReplay
  },
  {
    props: true,
    path: '/saas-results/:id',
    name: 'SaaSResults',
    component: SaasResults
  },
  {
    path: '/saas',
    name: 'SaaS',
    component: SaaS
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
