<template>
  <div class="main__stored">
  <!-- <v-card fill-height fluid> -->

    <!-- <v-row align="center" justify="center" class="pl-2 pr-2"> -->
      <div class="container-table">
      <div class="table">

        <v-table>
          <thead>
            <tr>
              <th class="text-left">
                Nombre
              </th>
              <th class="text-left">
                Ejercicio
              </th>
              <th class="text-left">
                Bien
              </th>
              <th class="text-left">
                Total
              </th>
              <th class="text-left">
              </th>
              <th class="text-left">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in results" :key="item.id">

              <td>{{ item.filename || item.ts }}</td>
              <td>{{ item.ejercicio || 'airsquat' }}</td>
              <td>{{ item.bien }}</td>
              <td>{{ item.total }}</td>
              <td v-if="item.progress == 1.0">
                <v-btn icon="mdi-play" color="primary" @click="getVideo(item.id)" size="xs-small">
                </v-btn>

              </td>
              <td v-else>
                <v-progress-circular :model-value="item.progress * 100" color="blue-grey"></v-progress-circular>
              </td>
              <td><v-btn icon="mdi-delete" color="warning" @click="deleteVideo(item.id)" size="xs-small"></v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>

      </div>
    <!-- </v-row> -->


  <!-- </v-card> -->
  </div>
</div>
</template>

<script>
import { auth, db, storage } from '@/plugins/firebase'
import { ref, deleteObject } from "firebase/storage";
import { collection, onSnapshot, deleteDoc, doc } from 'firebase/firestore';



import router from '@/routes/index'

export default {
  name: 'SaaS',
  components: {

  },
  computed: {
    dataset() {
      return {
        labels: ['Bien', 'Mal'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651'],
            data: [this.bien, this.mal]
          }
        ]
      }
    },
  },
  data: () => ({
    ejercicio: '',
    replay: false,
    errors: [],
    selectedVideo: {},
    reps: 0,
    bien: 0,
    mal: 0,
    currentFrame: 0,
    hideVideo: false,
    videoUrl: '',
    itemsPerPage: 5,
    results: [],
    headers: [
      { title: 'Timestamp', align: 'end', key: 'ts' },
      { title: 'Bien', align: 'end', key: 'bien' },
      { title: 'Total', align: 'end', key: 'total' }
    ],
    resultIds: [],
    userId: '1234',
    videoFile: null,
    videoData: null,
    unsubscribe: null,
    reader: null,
    url: null,
    rules: [
      value => {
        return !value || !value.length || value[0].size < 100000000 || 'File size should be less than 100 MB!'
      },
    ],
  }),
  mounted() {
    // Add firestore listener


    // Get user id
    auth.onAuthStateChanged(user => {

      this.userId = user.uid
      this.sub_db();
      if (!user) {
        router.push('/login')
        console.log('not logged in ', this.user.loggedIn)
      }
    });
  },
  unmounted() {
    this.unsubscribe();
  },
  methods: {
    async deleteVideo(id) {
      this.errors = []
      this.replay = false;
      // delete from the list
      const index = this.resultIds.indexOf(id);
      this.resultIds.splice(index, 1);
      const toDelete = this.results.filter(obj => id == obj.id);

      const fileName = toDelete[0].url;
      this.results = this.results.filter(obj => id != obj.id);


      // Get a reference to the file
      const fileRef = ref(storage, fileName);

      // Delete entry in firestore
      await deleteDoc(doc(db, this.userId, id));
      // Create a reference to the file to delete


      // Delete the file
      deleteObject(fileRef).then(() => {
        // File deleted successfully
      }).catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error)
      });

    },
    getVideo(id){
      router.push(`/saas-replay/${id}`)
    },
    async sub_db() {
      // Read documents from a collection
      // const querySnapshot = await getDocs(collection(db, this.userId));
      // querySnapshot.forEach((doc) => {
      //   console.log(`${doc.id} => ${doc.data()}`);
      //   this.results.append({id: doc.id, data: doc.data()})
      // });
      this.unsubscribe = onSnapshot(collection(db, this.userId), (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          if (!this.resultIds.includes(doc.id)) {
            this.resultIds.push(doc.id);

            if (data.url == "") {
              data.ts = 'Processing'
            } else {
              data.ts = data.ts ? data.ts.toDate().toLocaleDateString() : data.ts;
            }
            this.results.push(data);
          } else {
            data.ts = data.ts ? data.ts.toDate().toLocaleDateString() : data.ts;
            this.results = this.results.filter(obj => doc.id != obj.id);
            this.results.push(data);
          }

        });
      });
    },


  },
}
</script>

<style>

.table {
  border-radius: 10px;
}

.main__stored{
  background-image: url('@/assets/img/background_upload.png');
  background-size: contain;
  height: 90vh;
  background-position: center;
}

.container-table {
  display:flex;
  justify-content: center;
}
.text-field-transparent  .v-input__control {
  background: rgb(255, 205, 205) !important;
}

.bien {
  color: green;
}

.mal {
  color: red;
}

video {
  height: 50vh;
  /* width: 100vw; */
  /* object-fit: fill; */
}
</style>
