<template>
  <v-container class="mainrt">
    <v-row class="text-center">
      <v-col class="mb-1" cols="8">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to Revelify
        </h1>

      </v-col>
      <v-col cols="4">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="100"
        />
      </v-col>


    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="duracion"
          label="Duracion (sg)"
          :items="[30, 60, 90]"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="ejercicio"
          label="Ejercicio"
          :items="['Airsquat', 'Burpee']"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">

      </v-col>
      <v-col cols="3">
        <router-link :to="`/camera/${ejercicio}/${duracion}`">
          <v-btn variant="flat" color="indigo-lighten-4">
            Start
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'RealTime',
  components: {
  },
  data: () => ({
    duracion: 30,
    ejercicio: ''
  }),
}
</script>


<style scoped>
.mainrt {
  background-size: cover;
  height: 100vh;
}

.welcome {
  color: white;
}

.buttons {}
</style>
