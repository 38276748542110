// Import the functions you need from the SDKs you need
import * as firebaseui from 'firebaseui'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY || 'AIzaSyBXuWthbs4aeKlROCOyFy3EvnUY0FrmRBM',
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || 'test-revelify.firebaseapp.com',
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || 'test-revelify',
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || 'test-revelify.appspot.com',
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || '576318548252',
  appId: process.env.VUE_APP_FIREBASE_APP_ID || '1576318548252:web:bb4a8df2c4f59e9399f2d9',
  measurementId: process.env.VUE_APP_MEASUREMENT_ID || 'env-not-set'
}
console.log(process.env.VUE_APP_FIREBASE_API_KEY)

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
//initialize firebase auth
const auth = getAuth();
const db = getFirestore(app);
// Initialize the FirebaseUI Widget using the Firebase app.
var ui = new firebaseui.auth.AuthUI(auth)
const storage = getStorage(app);

export { app, auth, analytics, ui, db, storage }
