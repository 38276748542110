<template>
  <v-container class="main-upload">
    <!-- <v-img :src="require('../assets/img/background_upload.png')" class="pa-0" contain height="500px" /> -->
    <div class="upload" v-if="currentProgress < 0">

      <div class="pl-2 pr-2 text-center">
        <v-alert v-model="warning" variant="tonal" closable color="warning" icon="$warning" title="No Exercise selected"></v-alert>
        <h2 class="font-weight-bold mb-3 exercise-title-upload">
          Upload a video
        </h2>
      </div>
      <div class="upload__input">
        <!-- <v-col cols="4"> -->
        <div class="upload__items">
          <v-file-input v-model="videoFile" clearable show-size :rules="rules" label="File input" prepend-icon="mdi-video"
            accept="video/mp4" @click:clear="videoFile = null" @change="previewVideo"></v-file-input>
        </div>
        <div class="upload__items">
          <v-select class="exercise-selector" @update:modelValue="warning = false" v-model="ejercicio" label="Ejercicio"
            :items="['Airsquat', 'Burpee', 'Pushup','Pullup']"></v-select>
        </div>
        <div class="upload__items">
          <v-btn color="success" @click="upload" v-show="videoFile != null">Upload Video</v-btn>
        </div>
      </div>
      <div v-show="videoFile != null" class="video-results">
        <video autoplay muted class="video" id="video-preview" controls
          v-show="!hideVideo && (videoFile != null || videoUrl != '')" />
      </div>
    </div>

    <div v-else class="upload__progress">
      <div>
        <h3 class="progress-message"> PROCESANDO </h3>
        <div class="pt-5 pb-5">
          <ProgressBar :progress="currentProgress" />
        </div>
        <!-- <v-progress-linear v-if="currentProgress >= 0.0" :model-value="currentProgress" height="40px" rounded-bar="50" rounded="50">
      </v-progress-linear> -->

        <h3 class="progress-message">Confia, estamos haciendo nuestro RM</h3>
      </div>
    </div>






  </v-container>
</template>

<script>
import { auth, db } from '@/plugins/firebase'
import { collection, onSnapshot, orderBy, limit, query } from 'firebase/firestore';

import router from '@/routes/index'
import ProgressBar from '@/components/atoms/ProgressBar'

export default {
  name: 'SaaS',
  components: {
    ProgressBar
  },
  computed: {
    progress_bar_colour() {
      return "linear-gradient(135deg, rgba(254, 204, 0, 1) 0%, rgba(254, 125, 3, 1) 25%, rgba(244, 29, 18, 1) 50%, rgba(129, 11, 155, 1) 75%, rgba(51, 0, 247, 1) 100%)";
    },
    dataset() {
      return {
        labels: ['Bien', 'Mal'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651'],
            data: [this.bien, this.mal]
          }
        ]
      }
    },
  },
  data: () => ({
    warning: false,
    currentProgress: -1,
    tableInitialised: false,
    currentProcessingId: -1,
    existingExerciseIds: [],
    ejercicio: '',
    replay: false,
    errors: [],
    selectedVideo: {},
    reps: 0,
    bien: 0,
    mal: 0,
    currentFrame: 0,
    hideVideo: false,
    videoUrl: '',
    itemsPerPage: 5,
    results: [],
    headers: [
      { title: 'Timestamp', align: 'end', key: 'ts' },
      { title: 'Bien', align: 'end', key: 'bien' },
      { title: 'Total', align: 'end', key: 'total' }
    ],
    resultIds: [],
    userId: '1234',
    videoFile: null,
    videoData: null,
    unsubscribe: null,
    reader: null,
    url: null,
    rules: [
      value => {
        return !value || !value.length || value[0].size < 100000000 || 'File size should be less than 100 MB!'
      },
    ],
  }),
  mounted() {
    // Add firestore listener


    // Get user id
    auth.onAuthStateChanged(user => {

      this.userId = user.uid
      this.sub_db();
      if (!user) {
        router.push('/login')
        console.log('not logged in ', this.user.loggedIn)
      }
    });
  },
  unmounted() {
    this.unsubscribe();
  },
  methods: {

    async sub_db() {
      // Read documents from a collection
      // const querySnapshot = await getDocs(collection(db, this.userId));
      // querySnapshot.forEach((doc) => {
      //   console.log(`${doc.id} => ${doc.data()}`);
      //   this.results.append({id: doc.id, data: doc.data()})
      // });
      const q = query(collection(db, this.userId), orderBy("ts", "desc"), limit(100));
      this.unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.currentProcessingId = doc.id;
          if (this.tableInitialised) {
            // Check if update is for a new ID
            // If so, use it to update the progress bar
            if (this.existingExerciseIds.indexOf(doc.id) === -1) {
              if (data.progress < 1.0) {
                // Still processing, update the progress bar
                this.currentProgress = data.progress * 100 + 1;
              } else {
                // Processing finished, go to results screen
                this.currentProgress = data.progress * 100;
                router.push(`/saas-results/${this.currentProcessingId}`)
              }
            }
          } else {
            // Make a list of existing IDs
            this.existingExerciseIds.push(doc.id)
          }

        });
        this.tableInitialised = true;
      });
    },
    async upload() {
      if (this.ejercicio === '') {
        this.warning = true;
        return
      }
      this.currentProgress = 1;
      this.errors = []
      const formData = new FormData();
      formData.append('video', this.videoFile[0]);
      formData.append('uid', this.userId);
      formData.append('orgfilename', this.videoFile[0].name)
      formData.append('ejercicio', this.ejercicio.toLowerCase());
      this.hideVideo = true;
      this.videoFile = null;

      // const response = await fetch('http://localhost:8000/upload', {
      const response = await fetch('https://revelify-saas-geah2nlgea-no.a.run.app/upload', {
        method: 'POST',
        mode: 'no-cors',
        body: formData
      });
      // console.log(response)
      if (response.ok) {
        console.log('Video uploaded successfully');

      } else {
        console.log(response)
        console.error('Error uploading video');
      }
    },
    previewVideo() {
      this.errors = []
      this.hideVideo = false;
      this.replay = false;
      this.selectedVideo = {};
      // console.log(this.videoFile)
      // this.url= URL.createObjectURL(this.videoFile[0])
      let video = document.getElementById('video-preview');
      this.reader = new FileReader();
      this.reader.readAsDataURL(this.videoFile[0]);
      this.reader.addEventListener('load', function (event) {
        video.src = this.reader.result;
        this.videoData = {
          name: this.videoFile[0].name,
          type: this.videoFile[0].type,
          data: event.target.result,
        };

      }.bind(this));
    }
  },

}
</script>

<style>
.main-upload {
  position: relative;
  /* height: 85vh; */
  background-image: url('@/assets/img/background_upload.png');
  background-size: contain;
  height: 90vh;
  background-position: center;
}

.upload__input {
  display: flex;
  width: 100%;
}

@media screen and (max-width:700px) {
  .upload__input {
    flex-wrap: wrap;
  }

  .upload__items:first-child {
    flex-basis: 100%;
  }
}

.upload__items {
  margin-left: auto;
  width: 100%;
  padding-right: 20px;
  flex: 2;
}

.upload__items:nth-child(2) {
  flex: 1;
}

.upload__items:nth-child(3) {
  flex: 0.5;
  margin-top: 10px
}


.upload {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.upload__progress {
  position: absolute;
  top: 80px;
  left: 0px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}


.progress-message {
  color: #323582;
  text-align: center;
}

.video-results {
  background: linear-gradient(135deg, rgba(254, 204, 0, 1) 0%, rgba(254, 125, 3, 1) 25%, rgba(244, 29, 18, 1) 50%, rgba(129, 11, 155, 1) 75%, rgba(51, 0, 247, 1) 100%);
  border-radius: 10px;
  padding: 15px;
  display: flex;
}

.exercise-title-upload {
  font-size: x-large;
  color: #323582;
}

@media screen and (max-width: 700px) {
  .exercise-selector {
    padding-left: 40px;
  }
}
</style>
