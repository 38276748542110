<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <router-link to="/setup">
          <v-btn variant="flat" color="indigo-lighten-4">
            Try Again
          </v-btn>
        </router-link>
      </v-col>
      <v-col cols="6" class="text-right">
        <router-link to="/">
          <v-btn variant="flat" color="indigo-lighten-4">
            Finish
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-1" cols="8">
        <h1 class="display-2 font-weight-bold mb-3">
          Resultado - {{ ejercicio }}
        </h1>

      </v-col>
      <v-col cols="4">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="100"
        />
      </v-col>


    </v-row>
    <v-row>
      <v-col cols="4">
        <div>
          <h1>Total: </h1>
          <h2>{{ (Number(bien) + Number(mal)) || '-' }}</h2>
        </div>
        <div class="bien">
          <h1>Bien: </h1>
          <h2>{{ bien  || '-'}}</h2>
        </div>
        <div class="mal">
          <h1>Mal: </h1>
          <h2>{{ mal || '-'}}</h2>
        </div>
      </v-col>
      <v-col cols="8">
        <!-- {{ reps}} {{ errors }} -->
        <Pie :dataset="dataset" />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import Pie from '@/components/molecules/Pie'


export default {
  name: 'FinishScreen',
  props: ['bien', 'mal', 'ejercicio'],
  components: {
    Pie
  },
  data: () => ({

  }),
  computed: {
    dataset() {
      return {
        labels: ['Bien', 'Mal'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651'],
            data: [this.bien, this.mal]
          }
        ]
      }
    },
  },
}
</script>
