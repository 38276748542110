<template>
  <v-container>
    <!-- <v-img :src="require('../assets/img/background_upload.png')" class="pa-0" contain height="100%" /> -->
    <div class="replay-container">
      <div class="loading-container" v-if="exerciseTitle === ''">
        <img class="rotating" :src="require('@/assets/logo_128px.png')" />
        <h1 class="loading-text pt-4" align="center">Loading results</h1>
      </div>
      <div class="results-container" v-show="exerciseTitle">
        <div class="results-header">
          <div class="exercise-title">
            <h1> {{ exerciseTitle.toUpperCase() }}</h1>
          </div>
          <div>
            <CronoDisplay :value="`${currentTime}`" />
          </div>
        </div>

        <div class="results-wrapper">

          <!-- <v-row align="center" justify="center" class="pa-2"> -->
          <div class="results-item video-results">
            <video class="video-preview" autoplay id="video-preview" controls muted="muted"
              v-show="!hideVideo && (videoFile != null || videoUrl != '')" />
          </div>
          <div class="results-item">
            <div class="chart">
              <!-- {{ reps}} {{ errors }} -->
              <Pie :dataset="dataset" />
            </div>
            <div>
              <div class="container">
                <!-- <div class="box">
                <a>Total: {{ reps }}</a>
              </div> -->
                <div class="bien">
                  <h1>REPS: {{ bien }}</h1>
                </div>
                <div class="mal">
                  <h1>NO REPS: {{ mal }}</h1>
                </div>
              </div>

            </div>
          </div>
          <div class="results-item">
            <h2 class="error-title">motivo NO REP</h2>
            <div class="error-area">
              <h3 class="text-field" v-for="error in errors" :key="error">
                {{ error }}
              </h3>
            </div>
          </div>
        </div>
      </div>

    </div>
  </v-container>
</template>

<script>
import { auth, db, storage } from '@/plugins/firebase'
import { doc, getDoc } from 'firebase/firestore';
import Pie from '@/components/molecules/Pie'

import { ref, getDownloadURL } from "firebase/storage";
import router from '@/routes/index'
import { watch } from 'vue'


// import bkimg from '@/assets/img/background_upload.png'

import CronoDisplay from '@/components/atoms/CronoDisplay'

export default {
  name: 'SaaSResults',
  props: ['id', 'replayExercise'],
  components: {
    Pie,
    CronoDisplay
  },
  computed: {
    dataset() {
      return {
        // labels: ['Bien', 'Mal', ''],
        datasets: [
          {
            backgroundColor: [
              '#41B883',
              '#E46651',
              'rgba(255,255,255, 0)'
            ],
            data: [this.bien, this.mal, this.total - this.bien - this.mal]
          }
        ]
      }
    },
  },
  data: () => ({
    currentTime: '00:00',
    total: 0,
    exerciseTitle: '',
    replay: false,
    errors: [],
    exerciseData: {},
    reps: 0,
    bien: 0,
    mal: 0,
    currentFrame: 0,
    hideVideo: false,
    videoUrl: '',
    itemsPerPage: 5,
    results: [],
    headers: [
      { title: 'Timestamp', align: 'end', key: 'ts' },
      { title: 'Bien', align: 'end', key: 'bien' },
      { title: 'Total', align: 'end', key: 'total' }
    ],
    resultIds: [],
    userId: '1234',
    videoFile: null,
    videoData: null,
    unsubscribe: null,
    reader: null,
    url: null,
    rules: [
      value => {
        return !value || !value.length || value[0].size < 100000000 || 'File size should be less than 100 MB!'
      },
    ],
  }),
  setup(props) {
    watch(() => props.replayExercise, () => {
      // this.replayCount = newVal;
      console.log(this.replayCount)

    }).bind(this)
  },
  mounted() {

    // Get user id
    auth.onAuthStateChanged(user => {

      this.userId = user.uid
      this.sub_db();
      if (!user) {
        router.push('/login')
        console.log('not logged in ', this.user.loggedIn)
      }
    });
  },
  unmounted() {
  },
  methods: {
    uploadNew() {
      router.push('/saas-upload')
    },
    formatTime(ms) {
      const minutes = (Math.round(ms / 60) + "").padStart(2, "0")
      const seconds = (Math.round(ms % 60) + "").padStart(2, "0")
      return `${minutes}:${seconds}`
    },
    getVideo() {
      this.errors = []
      this.replay = true;
      const fileName = this.exerciseData.url;

      // Get a reference to the file
      const fileRef = ref(storage, fileName);

      // Get the download URL for the file
      getDownloadURL(fileRef)
        .then((url) => {
          // Create a new HTML video element and set its source to the download URL
          const videoElement = document.getElementById('video-preview');
          videoElement.src = url;


          videoElement.addEventListener("canplay", () => {
            this.exerciseTitle = this.exerciseData.ejercicio;
            this.total = this.exerciseData.total;
          })

          videoElement.addEventListener("timeupdate", () => {

            this.currentFrame = Math.round(videoElement.currentTime * this.exerciseData.fps)
            this.currentTime = this.formatTime(videoElement.currentTime);

            const rep_total = this.exerciseData.rep_total.filter(number => number < this.currentFrame).length
            const rep_bien = this.exerciseData.rep_bien.filter(number => number < this.currentFrame).length
            const rep_mal = this.exerciseData.rep_mal.filter(number => number < this.currentFrame).length

            // Video is playing a rep in the future
            if (rep_total - this.reps === 1) {
              // la nueva repetición ha sido un error
              if (this.mal !== rep_mal) {
                this.errors.push(`${this.formatTime(videoElement.currentTime)}: ${this.exerciseData[`${rep_total}`].join(',')}`)
              }
            } else if (rep_total !== this.reps) {
              // Video has rewind and is playing a previous rep
              // Recreate errors array up to current rep
              this.errors = []
              const repList = this.exerciseData.rep_mal.filter(number => number < this.currentFrame)
              const repTotalList = this.exerciseData.rep_total.filter(number => number < this.currentFrame)
              if (repList.length > 0) {
                for (let i = 0; i < repList.length; i++) {
                  const repNumber = repTotalList.indexOf(repList[i])
                  this.errors.push(`${this.formatTime(repList[i] / this.exerciseData.fps)}: ${this.exerciseData[repNumber + 1].join(',')}`)
                }
              }
            }

            this.reps = rep_total
            this.bien = rep_bien
            this.mal = rep_mal
          });

          this.videoUrl = url;
        })
        .catch((error) => {
          console.error(error);
        });

    },
    async sub_db() {
      const docRef = doc(db, this.userId, this.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        this.exerciseData = docSnap.data()
        this.getVideo(this.exerciseData)
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    },
  },
}
</script>

<style>
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;

}

.replay-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-button {
  margin-bottom: 0px;
  margin-left: 40px;
}

/* @media screen and (max-width: 700px) {
  .upload-button {

    margin-bottom: 20px
  }
} */


/* .main-results {
  background-image: url('@/assets/img/background_upload.png');
  background-size: contain;
  height: 100%;
} */

.chart {
  width: 100%;
}


.rotating {
  animation: rotate 7s linear 0s infinite;
  -webkit-animation: rotate 7s linear 0s infinite;
}

@keyframes rotate {
  0% {}

  100% {
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes rotate {
  0% {}

  100% {
    -webkit-transform: rotate(-360deg);
  }
}


.results-container {
  /* position: absolute;
  top: 20px;
  left: 0px; */
  /* margin-right: 20px; */
  width: 100%;
}

.loading-container {
  /* margin-top: 100px; */
  /* height: 80%; */
  justify-content: center;
}

.results-header {
  /* position: absolute;
  top: 0px;
  left: 0px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.loading-text {
  font-size: x-large;
  color: #323582;
}

.exercise-title {
  font-size: x-large;
  color: #323582;
}

@media screen and (min-width: 485px) {
  .exercise-title {
    margin-right: 40px;
  }
}

@media screen and (max-width: 485px) {
  .results-header {

    margin-bottom: 25px;
  }
}

.container {
  /* border: 2px solid black; */
  /*Making the container a flexbox*/
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 1rem;
  margin-bottom: 10px;
  /*Making equal spaced divs*/
  /* background-color: black; */
}



.text-field {
  /* background: rgb(245, 243, 243) !important; */
  color: black;
  font-weight: bold;
  /* min-height: 300px; */


}

.bien {
  color: green;
}

.mal {
  color: #F41D12;
}

.error-title {
  color: #F41D12;
}

.error-area {
  min-height: 400px;
  width: 100%;
  background: rgba(245, 243, 243, 0.8);
  border-radius: 10px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  padding: 10px;

}

.video-results {
  background: linear-gradient(135deg, rgba(254, 204, 0, 1) 0%, rgba(254, 125, 3, 1) 25%, rgba(244, 29, 18, 1) 50%, rgba(129, 11, 155, 1) 75%, rgba(51, 0, 247, 1) 100%);
  border-radius: 10px;
  padding: 15px;
  display: flex;
}

.results-wrapper {
  /* position: relative;
  bottom: 60vh; */
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  /* margin-bottom:150px; */
  margin: auto;
  flex-wrap: wrap;

}

.results-item {
  margin: auto;
  text-align: center;
  min-width: 25%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.video-preview {
  width: 100%;
}
</style>
